<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="识别成功" name="list"></el-tab-pane>
                <el-tab-pane label="陌生人" name="stranger"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'list'">
            <face-record-table />
        </div>
        <div v-if="webType === 'stranger'">
            <face-record-stranger />
        </div>
    </div>
</template>

<script>
import faceRecordTable from "./pages/faceRecordTable";
import faceRecordStranger from "./pages/faceRecordStranger";
export default {
    name: "faceAuthorization",
    components: {
        faceRecordTable,
        faceRecordStranger
    },
    data() {
        return {
            webType: "list",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>